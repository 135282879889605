html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Fira Sans', sans-serif;;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  letter-spacing: 0.02em;
  color: #101010;
  background: #ffffff;
  text-align: left;
  margin: 0
}

h1 {
  padding: 0 0 15px 0;
  margin: 0;
  color: #101010;
  line-height: 1.4em;
  font-weight: 800;
  letter-spacing: 0.025em;
}

h2 {
  color: #101010;
  line-height: 1.4em;
  font-weight: 900;
  letter-spacing: 0.025em;
  padding: 0 0 15px 0;
  margin: 0;
  font-size: 36px;
}

p {
  padding: 0 0 20px 0;
  margin: 0;
}

@media (max-width: 767px) {
  h1 {
    line-height: 1.25em;
    letter-spacing: 0.01em;
    font-size: 32px;
  }
  h2 {
    font-size: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }
}

** loader animation */
.loader-root {
  text-align: center;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 28px;
  height: 100vh;
  padding-left: 20px;
  position: relative;
}

.loader:before {
  animation: loading 2s linear infinite;
  content: attr(data-text);
  left: 20;
  max-width: 180px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.loader-light {
  color: rgba(0, 0, 0, .3);
}

.loader-light:before {
  color: rgba(0, 0, 0, .5);
}

.social-links {
  text-align: center;
}

.social-links a {
  /* float: right; */
  font-size: 24px;
  display: inline-block;
  background: #ffffff;
  color: #212121;
  line-height: 1.7;
  margin-right: 10px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
